@import "../../../../styles/_fonts.scss";
@import "../../../../styles/_sizes.scss";
@import "../../../../styles/_colors.scss";

.amp_container {
  .external_amp_color {
    background: $linus-blanket;
  }

  .amp_column {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    .icon_style {
      color: $green;
    }

    // below style sets icon to a size of 24px (xs is 16px and sm is 32px)
    i {
      font-size: $icon-size;
    }

    .icon_heading {
      color: $blue;
    }

    .amp_email_link {
      color: $blue-link;
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word;
    }

    .amp_text_internal {
      padding-left: 0.75rem; //to match padding of class details text in section
    }
  }
}

@media (min-width: $medium-size-screen) {
  .amp_container {
    .amp_column {
      border-bottom-left-radius: 0;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }
  }
}

@media print {
  .icon_style {
    margin-right: 0 !important;
    padding-right: 0 !important;
    width: auto !important;
  }
}
