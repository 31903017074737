@import "../../../../../styles/fonts";
@import "../../../../../styles/sizes";
@import "../../../../../styles/colors";

.mobile_results_table_container {
  .color_code_indicators_row {
    background: $linus-blanket;
  }

  // colors and styling for icons
  // this can NOT be in inheritance for color_code_indicators_row since these icons are used in 2 different places
  // adding below icons to color_code_indicators_row will remove styling elsewhere
  // using dashes for the icon classes so we can utilize the name of the icon as a classname to be used for styling (i.e. bullet-check-mark instead of bullet_check_mark)
  .bullet-check-mark {
    color: $green;
  }

  .bullet-not-valid {
    color: $red-error;
  }

  .align-items-end > div,
  .description_header,
  .header,
  .attention-alt,
  .questions_icon,
  .appetite_help {
    color: $blue;
  }

  .align-items-center > .fw-medium,
  .appetite_help {
    font-size: 1.25rem;
  }

  .cursor_pointer {
    cursor: pointer;
  }

  .business_search_table_text {
    color: $blue;
    margin-bottom: 1.25rem;
  }

  .business_search_table_header {
    font-size: 1.375rem;
    font-weight: $fw-500;
  }
}
