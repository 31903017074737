@import "../../../../../styles/fonts";
@import "../../../../../styles/colors";

.appetite_help_modal_container {
  .bullet_check_mark_icon {
    color: $green;
  }

  .bullet_not_valid_icon,
  .stop_icon {
    color: $red-error;
  }

  .attention_alt_icon,
  .questions_icon,
  .appetite_help {
    color: $blue;
  }

  .appetite_type_title {
    color: $blue;
  }

  .cursor_pointer {
    cursor: pointer;
    @include button-link;
  }

  // used to keep the question mark (?) icon next to Appetite Help from being pushed down.  Keeps it in line with text
  button [class*=" icon-"]:before {
    vertical-align: baseline;
  }
}
