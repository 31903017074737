@import "../../../styles/colors";

.mandatory_forms_container {
  //bootstrap margin goes up to 3 rem only, so setting custom css
  margin-top: 5rem;

  .header {
    color: $blue;
  }
}
