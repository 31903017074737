@import "../../../../styles/_sizes.scss";
@import "../../../../styles/_colors.scss";

.class_details_internal_container {
  .class_details_title {
    color: $blue;
  }

  .class_details_and_amp_card {
    border: 2px solid $blue-light;

    .class_details_card_border {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .class_details_card {
      background-color: $linus-blanket;
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

      .class_details_header {
        color: $blue;
      }

      .class_details_wc_codes {
        display: flex;
        flex-wrap: wrap;

        .pop_over {
          display: flex;
          height: 1.5rem; // set height to match height of all other text in section.  When utilizing map the HukPopover height was being set to 40px (2.5rem)

          p {
            color: $blue-link;
            letter-spacing: 0.2em;
          }
        }
      }

      .adm_text {
        color: $blue;
      }

      .column_border {
        border-right: 1px solid $gray;
      }
    }
  }
}
