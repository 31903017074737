@import "../../styles/sizes";
@import "../../styles/colors";

$title-font: 1.25rem;

/*
  below styles are shared between Class Details Page and Search Page
  styles are geared towards Class Details Page but '_SearchPage' is used to differentiate that style is specifically for the search page
*/
.header_container {
  .mobile_blue_header_border {
    border-top: 0.4rem solid $blue-light;
  }

  .header_logo {
    padding: 0;
    width: 4rem;
    height: 4rem;
    border: none;
    position: relative;
  }

  .title_mobile_SearchPage {
    color: $blue;
    font-weight: $fw-500;
    padding-left: 0.75rem;
    font-size: $title-font;
  }
}

@media (min-width: $small-size-screen) {
  .header_container {
    .primary_header_desktop {
      height: 3.25rem;
      background-color: $blue-light;

      .title_and_agency_row_SearchPage {
        color: $blue;
        height: 3.25rem; //used to match blue background height for centering a row

        .title_SearchPage {
          margin-left: 8rem;
          font-weight: $fw-500;
          font-size: $title-font;
        }
      }
    }

    .secondary_header_desktop {
      height: 3.75rem;
      border: 0.063rem solid $gray-light;
    }

    .header_logo {
      top: 0;
      width: 7rem;
      height: 7rem;
      padding: 1rem;
      position: absolute;
      background-color: white;
      border: 0.063rem solid $gray-light;
    }
  }
}

@media print {
  .secondary_header_desktop,
  .header_logo {
    border: none !important;
    padding: 0 !important;
    width: 6rem !important;
    height: 6rem !important;
  }
}
