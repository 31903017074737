@import "../../../../styles/sizes.scss";
@import "../../../../styles/colors.scss";

.uw_info_container {
  min-width: 21.5rem;
  background-color: $linus-blanket;

  .questions_text {
    color: $blue;
    font-size: 1.25rem;
    font-weight: $fw-500;
  }

  .quote_button {
    width: 16rem;
    margin: 1.875rem auto 1.25rem auto;
  }

  .uw_contact_text {
    text-align: center;
    line-height: 1.25rem;
  }

  .uw_email_link {
    color: $blue-link;
    text-decoration: none;
  }

  .phone_text {
    color: $blue-link;
  }
}
