@import "../../styles/colors";

.footer_container {
  flex-shrink: 0; // needed to keep footer at the bottom of the page
  padding: 3rem 0;
  height: 13.5rem; // matches EBC footer height
  margin-top: 8rem; // extra margin and spacing between the footer and the content above it
  border-top: 0.22rem solid $blue-light;

  .footer_content {
    font-size: 0.75rem;
    margin: 1.5rem 2.25rem 2.25rem 2.25rem;

    .footer_line {
      opacity: 100%; // overwrites bootstrap default opacity
      margin-top: 1.25rem;
      margin-bottom: 0.536rem;
      border-top: thin solid $gray-light;
    }

    ul {
      padding: 0;
      list-style: none;
      margin-bottom: 0.625rem !important; // overwrite bootstrap ul margin bottom

      li {
        display: inline-block;

        // adds the "|" spacers after each element in footer
        &:not(:last-child)::after {
          content: "|";
          padding: 0 0.375rem;
        }

        a {
          color: $gray;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        a.californiaPrivacyLink {
          position: relative;
          padding-right: 3.4em;

          &::after {
            top: 0;
            right: 0;
            content: "";
            width: 3.1em;
            height: 1.2em; // keeping in em format to match EBC
            position: absolute;
            background: url(../../assets/images/privacyoptions.svg) no-repeat
              center right;
          }
        }
      }
    }
  }
}
