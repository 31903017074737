@import "../../../../styles/sizes.scss";
@import "../../../../styles/colors.scss";

.appetite_container {
  .appetite_icon_and_text {
    font-weight: $fw-500;
  }

  .lob_type {
    color: $blue;
  }

  i {
    font-size: $icon-size; // sets all icons to a size of 22px (huk size xs is 16px and sm is 32px)
  }
}
