@import "../../../../../styles/colors.scss";

.wc_desktop_results_table_container {
  .header {
    color: $blue;
  }

  hr {
    @include huk-table-bottom-line;
  }
}
