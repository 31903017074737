@import "../../../styles/_sizes.scss";
@import "../../../styles/_colors.scss";

// used to keep row height consistent based on if there is or is not a HUK icon
// without this spacing is off in scearnios there is no HUK Icon
$row-height-mobile: 1.5rem;

.appetite_guide_container {
  .appetite_table_header,
  .column_headers {
    color: $blue;
  }

  .appetite_row {
    height: $row-height-mobile; // used to help spacing in relation to HUK icons on mobile
  }

  hr {
    margin: 0px;
  }

  .blue_border {
    opacity: 100% !important; // override the default opacity that comes with bootstrap hr element which is set to 25%
    border-bottom: 0.167rem solid $blue !important;
  }

  .lob_type {
    color: $blue;
    font-weight: $fw-500; // to make sure it matches the same fw
  }

  .fw-normal {
    color: $gray;
  }

  .column_headers,
  .appetite_table_header > h2 {
    position: static; // removes relative positioning and puts it back to static for smaller screens
  }

  .column_headers {
    left: -1rem; // used to move column headers titles left to be more centered over columns to closer match to wireframe
  }

  .appetite_header_internal > h2 {
    bottom: -1rem; // used to move table title down to closer match wireframe
  }
}

@media (min-width: $large-size-screen) {
  .appetite_guide_container {
    .column_headers,
    .appetite_table_header > h2 {
      position: relative; // needed to move titles down and left to closer match wireframe
    }
  }
}

@media (min-width: $medium-size-screen) {
  .appetite_guide_container {
    .appetite_row {
      height: auto; // reset height for desktop
    }
  }
}

@media print {
  .col-md-3 {
    width: 25% !important;
  }

  .mt-md-0 {
    margin-top: 0;
  }

  .lob_type {
    padding-bottom: 0.5rem !important;
  }
}
