// screen sizes below line up with bootstrap breakpoints for consistency
$small-size-screen: 576px;
$medium-size-screen: 768px;
$large-size-screen: 992px;
$extra-large-size-screen: 1200px;
$extra-extra-large-size-screen: 1400px;

$fw-500: 500;
$icon-size: 1.375rem;
$border-radius: 0.313rem;
