@import "../../../../styles/colors";

.uw_questions_container {
  color: $blue-link;
  text-decoration: none;
}

.uw_questions_button {
  color: $blue-link;
  @include button-link;
}
