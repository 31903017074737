@import "../../styles/fonts";
@import "../../styles/sizes";
@import "../../styles/colors";

.search_page_container {
  h1 {
    font-size: $h1;
  }

  .sales_appetite_title {
    color: $blue;
  }

  .spectrum_class_search_tab {
    .dividing_line_bottom {
      margin: 3rem 0 2rem 0;
      border-top: 0.5rem solid $linus-blanket;
    }
  }

  .wc_search_tab {
    .dividing_line_bottom {
      margin: 3rem 0 2rem 0;
      border-top: 0.5rem solid $linus-blanket;
    }
  }

  .appetite_guide_tab {
    .dividing_line_bottom {
      margin: 3rem 0 2rem 0;
      border-top: 0.5rem solid $linus-blanket;
    }
  }
}
