@import "/src/styles/_colors.scss";

html,
body,
#root {
  height: 100%;
  margin: 0;
  display: flex; // needed to keep footer at the bottom of the page
  flex-direction: column;
}

body {
  color: $gray; // default color for all text for entire app
  font-family: "HCo Gotham", arial, sans-serif; // default text for entire app
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
