@import "../../styles/colors";

.loading_indicator_container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading_indicator_font_color {
    color: $blue;
  }

  .search_text_style {
    font-weight: 600;
    font-size: 1.5rem;
    @extend .loading_indicator_font_color;
  }
}
