@import "../../../styles/_colors.scss";
@import "../../../styles/_sizes";

.max_limits_container {
  .small_commercial_title {
    color: $blue;
  }

  .container-fluid {
    .blue_font_color {
      color: $blue;
    }

    hr {
      width: 100%;
    }

    .blue_border {
      width: 100%;
      opacity: 100% !important;
      border-top: 0.2rem solid $blue;
    }

    .table_data {
      font-weight: $fw-500;
    }

    .sub_line_text {
      color: $gray;
      font-weight: 400;
      font-size: 0.75rem;
    }

    .exceed_limits_column {
      background: $linus-blanket;

      .exceed_limits_style {
        font-size: 1.5rem;
        font-weight: $fw-500;
      }

      .contactlink {
        color: $blue-link;
      }
    }
  }
}

@media (min-width: $extra-large-size-screen) {
  .max_limits_container {
    .container-fluid {
      // set the width of the hr to 100% for mobile
      hr {
        width: 93%;
      }
    }
  }
}

@media print {
  .exceed_limits_style {
    font-size: 1rem !important;
  }

  .exceed_limits_text {
    font-size: 0.75rem !important;
  }
}
