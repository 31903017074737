@import "/src/styles/sizes.scss";
@import "/src/styles/_fonts.scss";
@import "/src/styles/_colors.scss";

.error_message_container {
  /*
    used to offset the margin-top 8rem from the footer to center the text only for error page
    if it's search page it will not be added as those errors are displayed for search results and we don't want margin
  */
  .error_message_page {
    margin-top: 8rem;
  }

  .info-icon {
    color: $blue;
  }

  h1 {
    color: $blue;
    font-size: $h1;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0px;
    font-weight: $fw-500;
  }

  .message {
    font-size: 1rem;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0px;
  }

  .return_to_Search_link {
    color: $blue-link;
  }
}
