@import "../../../../styles/sizes.scss";
@import "../../../../styles/colors.scss";

.agency_instructions_container {
  i,
  .huk_popover_size {
    font-size: $icon-size; // sets all icons to a size of 22px (huk size xs is 16px and sm is 32px)
  }

  .agency_instructions {
    color: $blue-link;
  }

  a.agency_instructions {
    text-decoration: none;
  }
}

@media print {
  .agency_instructions_container {
    max-width: none !important;
    padding: 0 !important;
    margin-left: 0.7rem !important;
  }
}
