@import "../../../../styles/sizes.scss";
@import "../../../../styles/colors.scss";

.UWIndex_container {
  .lob_type {
    color: $blue;
    font-weight: $fw-500;
  }

  .uw_index_letter {
    &.index-L {
      color: $green;
    }

    &.index-M {
      color: $blue;
    }

    &.index-H {
      color: $red-error;
    }
  }

  // removing default button styles to make it look like normal text
  button {
    @include button-link;
  }
}
