@import "../../styles/fonts";
@import "../../styles/colors";

.class_detail_page_container {
  .container {
    // Global styles across all child Class Detail Page components
    h1 {
      font-size: $h1;
    }

    h2 {
      font-size: $h2;
    }
  }

  .return_to_results_link {
    color: $blue-link;
  }
}

@media print {
  .class_detail_page_container {
    .container {
      margin-top: 0 !important;
    }
  }
}
