@import "../../../../styles/_fonts.scss";
@import "../../../../styles/_sizes.scss";
@import "../../../../styles/_colors.scss";

.class_details_container {
  .class_details_title {
    color: $blue;
  }

  .class_detail_card {
    border: 2px solid $blue-light;

    .class_code_link {
      color: $blue-link;
    }

    .class_detail_color {
      display: flex;
      flex-wrap: wrap;

      .pop_over {
        display: flex;
        height: 1.5rem; // set height to match height of all other text in section.  When utilizing map the HukPopover height was being set to 40px (2.5rem)

        p {
          letter-spacing: 0.2em;
        }
      }
    }
  }
}

@media print {
  .class_detail_card {
    border: none !important;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .ps-4 {
      padding-left: 0 !important;
    }

    .amp_container {
      padding-left: 1rem !important;
    }
  }
}
