@import "../../../../styles/sizes";
@import "../../../../styles/colors";

.mobile_mandatory_forms_container {
  .blue_border_top {
    border-top: 0.2rem solid $blue;
    opacity: 100%;
  }
  .heading {
    color: $blue;
    font-weight: $fw-500;
  }

  .blue_border_bottom {
    border-top: 0.15rem solid $blue;
    opacity: 100%;
  }
}
