@import "../../../../styles/sizes.scss";
@import "../../../../styles/colors.scss";

.hazard_container {
  .lob_type {
    color: $blue;
    font-weight: $fw-500;
  }

  .fw_500 {
    font-weight: $fw-500; // bootstrap fw are for 300, 400 and 700 so setting custom fw
  }
}
