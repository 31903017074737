@import "../../../styles/_colors.scss";
@import "../../../styles/sizes";

.industry_guide_container {
  .pdf_message {
    color: $blue;
    background-color: $linus-blanket;

    i {
      color: $blue-link;
      font-size: $icon-size;
    }

    p {
      .state {
        color: $blue;
        font-weight: $fw-500;
      }
    }

    // style to make a button look like a link
    .spectrum_tab_link {
      padding: 0;
      border: none;
      background: none;
      color: $blue-link;
      font-weight: $fw-500;
    }
  }
}
