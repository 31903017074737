@import "../../../styles/_sizes";
@import "../../../styles/_colors";

.spectrum_class_search_container {
  .dividing_line_bottom_mobile {
    border-top: 0.5rem solid $linus-blanket;
  }
}

@media (min-width: $medium-size-screen) {
  .spectrum_class_search_container {
    // divider line is only on desktop so starting at medium sized screens and larger. Not needed on mobile
    .spectrum_vertical_divider {
      height: auto;
      margin-left: 2.5rem;
      border-left: 0.5rem solid $linus-blanket;
    }
  }
}

@media (min-width: $large-size-screen) {
  .spectrum_class_search_container {
    .spectrum_vertical_divider {
      margin-left: 3.5rem;
    }
  }
}

@media (min-width: $extra-large-size-screen) {
  .spectrum_class_search_container {
    .spectrum_vertical_divider {
      margin-left: 2rem;
    }
  }
}

@media (min-width: $extra-extra-large-size-screen) {
  .spectrum_class_search_container {
    .spectrum_vertical_divider {
      margin-left: 3.5rem;
    }
  }
}