@import "../../../styles/sizes.scss";
@import "../../../styles/_colors.scss";

.class_code_details_container {
  .class_type_title {
    color: $blue;
  }

  .class_code {
    color: $blue;
    font-weight: $fw-500;
  }

  .desc_code {
    color: $blue;
    font-weight: $fw-500;
  }

  .description_title {
    color: $blue;
  }
}

@media print {
  .container,
  .container-fluid {
    width: 100% !important;
    max-width: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}
