@import "../../../../../styles/fonts";
@import "../../../../../styles/sizes";
@import "../../../../../styles/colors";

.desktop_results_table_container {
  .header {
    color: $blue;
  }

  .color_code_indicators_row {
    font-size: 0.875rem;
    background: $linus-blanket;
  }

  // colors and styling for icons
  .bullet_check_mark_icon {
    color: $green;
  }

  .appetite_spacing {
    padding-right: 13.5rem;
  }

  .bullet_not_valid_icon {
    color: $red-error;
  }

  .attention_alt_icon {
    color: $blue;
  }

  i {
    font-size: $icon-size;
  }

  .business_search_table_text {
    color: $blue;
    margin-bottom: 1.25rem;
  }

  .business_search_table_header {
    font-size: 1.375rem;
    font-weight: $fw-500;
  }
}
