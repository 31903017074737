$gray: #484848;
$blue: #3a5a78;
$green: #118654;
$red-error: #b83b2f;
$blue-link: #1570be;
$blue-light: #b6d3e9;
$gray-light: #dadada;
$linus-blanket: #eaf2f8;

// common styles converting a button to look like a link
@mixin button-link {
  padding: 0;
  border: none;
  background: none;
}

@mixin huk-table-bottom-line {
  opacity: 100%;
  margin-top: -23px;
  border-top: 3px solid #3a5a78;
}
